import React, { useContext, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import HomeIcon from "@mui/icons-material/Home";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { FaDiscord, FaRegCopy, FaTelegram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Store } from "../context/Store/Store";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import ImageUploadComponent from "./ImageUploadComp";

const sections = [
  {
    title: "Finance",
    icon: <GroupAddIcon style={{ color: "#fff" }} />,
    links: [
      // { text: "Buy Share", to: "/buy-dvt" },
      // { text: "Claim DVT", to: "/claim-dvt" },
      // { text: "Buy ICO", to: "/buy-ico" },
      { text: "Swap DVT", to: "/swap-dvt" },
    ],
  },
  {
    title: "Transactions",
    icon: <GroupAddIcon style={{ color: "#fff" }} />,
    links: [
      // { text: "Claim History", to: "/claim-history" },
      // { text: "Buy History", to: "/buy-history" },
      // { text: "Referral History", to: "/referralReward-history" },
      { text: "Swap History", to: "/swap-history" },
      // { text: "Airdrop History", to: "/airdrop-history" },
    ],
  },
  // {
  //   title: "Team",
  //   icon: <GroupAddIcon style={{ color: "#fff" }} />,
  //   links: [
  //     { text: "Direct", to: "/direct-team" },
  //     { text: "Generation", to: "/total-team" },
  //   ],
  // },
];

const Sidebar = () => {
  const [openSection, setOpenSection] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const { userDatabaseData, copyToClipboardReferral } = useContext(Store);
  const { address, isConnected } = useWeb3ModalAccount();
  const [referralAddress, setReferralAddress] = useState();

  useEffect(() => {
    if (address && isConnected) {
      setReferralAddress(address);
    } else {
      setReferralAddress("..");
    }
  }, [address]);

  const handleClick = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleImageUpload = (image) => {
    // console.log("Image uploaded:", image);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main-sidebar">
      {/* Hamburger Menu Icon */}
      {window.innerWidth < 768 && (
        <IconButton
          onClick={toggleSidebar}
          style={{
            position: "fixed",
            left: "10px", // Change to right
            top: "20px",
            zIndex: 1000,
            color: "#fff",
          }}
        >
          {isSidebarOpen ? (
            <CloseIcon
              style={{ position: "fixed", left: "200px", top: "28px" }}
            />
          ) : (
            <MenuIcon />
          )}{" "}
          {/* Toggle between icons */}
        </IconButton>
      )}

      {/* Sidebar Overlay */}
      {isSidebarOpen && window.innerWidth < 768 && (
        <div
          className="overlay"
          onClick={toggleSidebar}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 400,
          }}
        />
      )}

      {/* Sidebar Container */}
      <div
        style={{
          width: isSidebarOpen ? "250px" : "0px",
          height: "100vh",
          backgroundColor: "#343c48",
          position: "fixed",
          left: "0",
          top: "0",
          zIndex: 500,
          borderRight: "1px solid #000",
          display: "flex",
          flexDirection: "column",
          alignItems: isSidebarOpen ? "flex-start" : "center",
          transition: "width 0.3s ease",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className="sidebar-wrap"
      >
        {/* Sidebar Logo */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: "1px solid #000",
            backgroundColor: "#303641",
            padding: "10px",
            marginBottom: "20px",
            height: "80px",
          }}
        >
          <img
            src="/assets/images/logo.png"
            alt="Logo"
            style={{ height: "50px", width: "auto" }}
          />
        </div>

        {/* Sidebar Links */}
        <div className="sidebar-links-con">
          <div className="sidebar-profile">
            <ImageUploadComponent
              defaultImage={
                userDatabaseData?.userProfileImage
                  ? userDatabaseData?.userProfileImage
                  : "/assets/images/default-img.png"
              }
              onImageUpload={handleImageUpload}
            />
            <div className="m-0">
              <p className="m-0">Hello 👋</p>
              <h6 className="m-0 text-nowrap">{userDatabaseData?.userName}</h6>
            </div>
          </div>
          <Link to={"/dashboard"}>
            <div className="back-office-links sidebar-link active">
              <HomeIcon />
              <span style={{ marginLeft: "10px" }}>
                <h3>Dashboard</h3>
              </span>
            </div>
          </Link>

          {sections.map((section) => (
            <div key={section.title}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleClick(section.title)}
                  sx={{ gap: "12px" }}
                >
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  {isSidebarOpen && (
                    <ListItemText
                      style={{ color: "#fff", fontSize: "22px" }}
                      primary={section.title}
                    />
                  )}
                  {openSection === section.title ? (
                    <ExpandLess style={{ color: "#fff" }} />
                  ) : (
                    <ExpandMore style={{ color: "#fff" }} />
                  )}
                </ListItemButton>
              </ListItem>
              {isSidebarOpen && (
                <Collapse
                  in={openSection === section.title}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {section.links.map((link) => (
                      <ListItemButton
                        key={link.to}
                        sx={{ pl: 4, color: "#fff", fontSize: "20px" }}
                        component={Link}
                        to={link.to}
                      >
                        <ListItemText primary={link.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}

          
        </div>

        {/* FOR MOBILE */}
        {isSidebarOpen && (
          <div className="mobile-content">
            <div className="url-con">
              <img
                src="/assets/images/icons/url-icon.png"
                alt="url-icon"
                className="url-img"
              />
              <input
                placeholder={`Referral../${referralAddress?.slice(
                  0,
                  3
                )}...${referralAddress?.slice(-3)}`}
              />
              <FaRegCopy
                onClick={() => copyToClipboardReferral()}
                className="copy-img"
              />
            </div>
            <div className="header-action-con">
              <div className="clipboardcheck-con">
                <FaTwitter />
              </div>
              <div className="handban-con">
                <FaDiscord size={25} />
              </div>
              <div className="exclamation-con">
                <FaTelegram size={28} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
