import React, { useState, createContext, useEffect } from "react";
import { ethers } from "ethers";

/////////////////////////////////////////// BINANCE CHAIN PRESALE ////////////////////////////////////
import dvtPrivatesaleContractAddress from "../contractsData/DoofyventurePrivateSale-address.json";
import dvtPrivatesaleContractAbi from "../contractsData/DoofyventurePrivateSale.json";

import usdtContractAddress from "../contractsData/usdtToken-address.json";
import dvtTokenContractAddress from "../contractsData/DoofyToken-address.json";
import tokenContractAbis from "../contractsData/usdtToken.json";

/////////////////////////////////////////// BINANCE CHAIN SWAP ////////////////////////////////////

import DoofySwapContractAddress from "../contractsData/DoofySwap-address.json";
import DoofySwapContractAddressAbi from "../contractsData/DoofySwap.json";

/////////////////////////////////////////// BINANCE CHAIN ICO Sale /////////////////////////////////

import DoofyventureICOSaleAddress from "../contractsData/DoofyventureICOSale-address.json";
import DoofyventureICOSaleAbi from "../contractsData/DoofyventureICOSale.json";


/////////////////////////////////////////// BINANCE CHAIN Airdop Sale /////////////////////////////////

import DoofyVenturesAirdropAddress from "../contractsData/Doofy_Ventures_Airdrop-address.json";
import DoofyVenturesAirdropAbi from "../contractsData/Doofy_Ventures_Airdrop.json";

import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ToastContainer, toast } from "react-toastify";
import { formatUnits } from "ethers/lib/utils";
import apis from "../Services";
import { BuyHistory, ClaimHistory } from "../../screens";
import { useNavigate } from "react-router-dom";

const getProviderPresaleContract = () => {
  const providers = process.env.REACT_APP_RPC_URL_BNB;
  const provider = new ethers.providers.JsonRpcProvider(providers); //"http://localhost:8545/"
  const presaleContract = new ethers.Contract(
    dvtPrivatesaleContractAddress.address,
    dvtPrivatesaleContractAbi.abi,
    provider
  );
  return presaleContract;
};

export const Store = createContext();

export const StoreProvider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loader, setloader] = useState(false);

  const [userDatabaseData, setUserDatabaseData] = useState({
    referral_reward: 0,
    wallet_address: "",
    userName: "",
    phone_Number: "",
    email: "",
    userLevel: 1,
    referredBy: "",
    userProfileImage: "",
    totalChildrensTeam: "0",
    totalDirectUsers: "0",
    direactBusiness: "0",
    totalReferralReward: "0",
    totalReferralShareOfDirectUsers:"0",
    totalAirdrop: "0",
    isProfileCompleted: false,
    DirectUsersData: [],
    TeamUsersData: [],
  });

  const [userHistoryData, setUserHistoryData] = useState({
    buyHistory: [],
    claimHistory: [],
    swapHistory: [],
    referralHistory: [],
    airdropHistory: [],
  });

  const [userContractData, setUserContractData] = useState({
    remainTimeInClaim: 0,
    dvtBuy_tokens: 0,
    totaldvtBuy_tokens: 0,
    dvtWallet_tokens: 0,
    smartContractPlans: [],
  });

  const [icoContractData, setICOContractData] = useState({
    remainTimeInICOClaim: 0,
    dvtBuyICO_tokens: 0,
    totaldvtBuyICO_tokens: 0,
    usdtInWallet:0,
    icoSalePrice: 0,
    swapPrice:0,
    isICOSale: false,
  });

  
  const [airdropContractData, setAirdropContractData] = useState({
    remainTimeInAirdropClaim: 0,
    remainBalance: 0,
    totalRecivedAmount: 0,
    isReadyToClaim: false,
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [referraAddress, setReferraAddress] = useState("0x6c87a464cDB6E8BeF6D07699a5c71CaF027Ab551");

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const GetValues = async () => {
    try {
      if (address && isConnected) {
        // setloader(true);
        // console.log("checkkkkkkk");
        const providers = process.env.REACT_APP_RPC_URL_BNB;
        // console.log(providers, "providersprovidersproviders");
        const provider = new ethers.providers.JsonRpcProvider(providers);
        // console.log("checkkkkkkk");
        const dvtPresaleContracts = new ethers.Contract(
          dvtPrivatesaleContractAddress.address,
          dvtPrivatesaleContractAbi.abi,
          provider
        );

        const dvtTokenContracts = new ethers.Contract(
          dvtTokenContractAddress.address,
          tokenContractAbis.abi,
          provider
        );

        const swapContract = new ethers.Contract(
          DoofySwapContractAddress.address,
          DoofySwapContractAddressAbi.abi,
          provider
        );

        const TokensInContract = await dvtPresaleContracts.users(
          address?.toString()
        );

        const TokensInWallet = await dvtTokenContracts.balanceOf(
          address?.toString()
        );

        const TimeInDays = await dvtPresaleContracts.releaseInterval();

        const getAllPlans = await dvtPresaleContracts.getAllPlans();

        const swapRatePrice = await swapContract.exchangeRate();

        // console.log(getAllPlans?.toString(), "getAllPlans");
        // console.log(getAllPlans, "getAllPlansWS");
        // console.log(
        //   +TokensInContract?.lastReleaseTime?.toString() + +TimeInDays,
        //   "TimeInDaysTimeInDaysTimeInDaysTimeInDays"
        // );

        // Transform the BigNumber values into JavaScript numbers/strings
        const transformedPlans = getAllPlans.map((plan) => ({
          usdtAmount: ethers.utils.formatUnits(plan[0], 18)?.toString(), // Adjust decimals if necessary
          tokenAmount: ethers.utils.formatUnits(plan[1], 18)?.toString(), // Adjust decimals if necessary
        }));

        // Update the state with transformed plans
        setUserContractData((prevData) => ({
          ...prevData,
          smartContractPlans: transformedPlans,
        }));

        setUserContractData((prevState) => ({
          ...prevState,
          dvtWallet_tokens: formatUnits(TokensInWallet, 18)?.toString(),
          dvtBuy_tokens: formatUnits(
            TokensInContract?.lockedBalance,
            18
          )?.toString(),
          totaldvtBuy_tokens: formatUnits(
            TokensInContract?.totalBuyAmount,
            18
          )?.toString(),
          remainTimeInClaim: ((+TokensInContract?.lastReleaseTime?.toString() + +TimeInDays) * 1000),
        }));
        
        setICOContractData((prevState) => ({
          ...prevState,
          swapPrice: formatUnits(swapRatePrice, 18)?.toString()
        }));
      }
    } catch (error) {
      setloader(false);
    }
  };

  const copyToClipboardReferral = () => {
    // const tokenAddress = `https://app.doofyventures.com/${address}`;//TODO:: Your token address
    // const tokenAddress = `https://dvt-frontend.vercel.app/${address}`;
    navigator.clipboard
      .writeText("")
      .then(() => {
        toast.success("Referral address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const copyWalletAddress = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success("User address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const BuyWithUSDTandUSDC = async (arrayAddresses, planIndex) => {
    if (!isConnected) {
      setloader(false);
      return toast.error("Please Connect Your Wallet.");
    }

    if (planIndex < 0 || planIndex > 5) {
      setloader(false);
      return toast.error("Please select a valid plan (0 to 5).");
    }

    try {
      setloader(true);
      // Initialize the provider and signer
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      // Create contract instances
      const USDTContracts = new ethers.Contract(
        usdtContractAddress.address,
        tokenContractAbis.abi,
        signer
      );

      const privateSale = new ethers.Contract(
        dvtPrivatesaleContractAddress.address,
        dvtPrivatesaleContractAbi.abi,
        signer
      );

      // const selectedPlan = plans[planIndex];
      const selectedPlan = await privateSale.plans(planIndex);
      const usdtAmountToApprove = selectedPlan?.usdtAmount;
      const dvtTokenToBuy = selectedPlan?.tokenAmount;

      // Check USDT balance for the first buyer
      const usdtBalance = await USDTContracts.balanceOf(arrayAddresses[0]);
      if (usdtBalance.lt(usdtAmountToApprove)) {
        setloader(false);
        return toast.error("Insufficient USDT balance.");
      }

      // Check allowance
      const allowance = await USDTContracts.allowance(
        arrayAddresses[0],
        dvtPrivatesaleContractAddress.address
      );

      if (allowance.lt(usdtAmountToApprove)) {
        // Approve the required USDT amount if allowance is insufficient
        let tokenApprove = await USDTContracts.approve(
          dvtPrivatesaleContractAddress.address,
          usdtAmountToApprove.toString()
        );
        await tokenApprove.wait();
      }

      // Call the buyTokens function with the array of addresses and the plan index
      const buying = await privateSale.buyTokens(arrayAddresses, planIndex);
      await buying.wait();
      let res = await apis.sendEmailAPI({
        email:userDatabaseData?.email,
        name: userDatabaseData?.userName,
        tokens: ethers.utils.formatEther(dvtTokenToBuy?.toString())?.toString()
      });
      // console.log(res, "resresEmail");
      const bnbLink = `https://bscscan.com/tx/${buying.hash}`;
      // console.log(`Transaction Hash: ${bnbLink}`);
      GetValues();
      getOneUser();
      setloader(false);
    } catch (error) {
      toast.error(`${error.reason ? error.reason : error.message}`);
      console.log(error);
      setloader(false);
    }
  };

  const claimTokens = async () => {
    try {
      setloader(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const presaleContract = new ethers.Contract(
        dvtPrivatesaleContractAddress.address,
        dvtPrivatesaleContractAbi.abi,
        signer
      );

      const start = await presaleContract.releaseTokens(address);
      // let tokens = ethers.utils.parseEther("10000000")
      // const start = await presaleContract.withdrawTokens(tokens);
      await start.wait();
      await GetValues();
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  const swapDoofyTokens = async (_doofy) => {
    try {
      setloader(true);
      if (!isConnected) {
        setloader(false);
        return toast.error("Please Connect Your Wallet.");
      }
      if (_doofy <= 0) {
        setloader(false);
        return toast.error("Amount must be greater-than Zero.");
      }
      if (+userContractData?.dvtWallet_tokens < +_doofy?.toString()) {
        setloader(false);
        return toast.error("You dont have enough balance.");
      }
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const dvtTokenContracts = new ethers.Contract(
        dvtTokenContractAddress.address,
        tokenContractAbis.abi,
        signer
      );

      const swapContract = new ethers.Contract(
        DoofySwapContractAddress.address,
        DoofySwapContractAddressAbi.abi,
        signer
      );

      let doofyInWei = ethers.utils.parseEther(_doofy?.toString());

      let tokens = await swapContract.getUSDTAmount(doofyInWei?.toString());
      // console.log(tokens?.toString(), "tokenstokenstokens");

      // Check USDT balance for the first buyer
      const usdtBalance = await dvtTokenContracts.balanceOf(address);
      if (usdtBalance.lt(doofyInWei)) {
        setloader(false);
        return toast.error("Insufficient USDT balance.");
      }

      // Check allowance
      const allowance = await dvtTokenContracts.allowance(
        address,
        DoofySwapContractAddress.address
      );

      if (allowance.lt(doofyInWei)) {
        // Approve the required USDT amount if allowance is insufficient
        let tokenApprove = await dvtTokenContracts.approve(
          DoofySwapContractAddress.address,
          doofyInWei.toString()
        );
        await tokenApprove.wait();
      }

      const start = await swapContract.swapDoofyForUSDT(doofyInWei);
      // let tokens = ethers.utils.parseEther("10000000")
      // const start = await presaleContract.withdrawTokens(tokens);
      await start.wait();
      await GetValues();
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  // const networkChange = async () => {
  //   let chainid = process.env.NEXT_PUBLIC_CHAIN_ID_ETHEREUM;
  //   if (isConnected && chainId?.toString() !== chainid?.toString()) {
  //     console.log(chainid, chainId, "chainidchainid");
  //     useSwitchNetwork(Number(chainid));
  //     return;
  //   }
  // };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// APIS //////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const getOneUser = async () => {
    try {
      if (isConnected) {
        let dbData = await apis.getOneUser(address?.toString()); //TODO:address?.toString()
        // console.log(dbData, "dbDatadbDatadbData");
        setUserDatabaseData((prevState) => ({
          ...prevState,
          userName: dbData?.data?.user?.user_name,
          phone_Number: dbData?.data?.user?.phone_number,
          email: dbData?.data?.user?.email,
          userProfileImage: dbData?.data?.user?.profile_image,
          isProfileCompleted: dbData?.data?.user?.isComplete,
          userLevel: dbData?.data?.user?.level,
          referredBy: dbData?.data?.user?.parent_wallet_address,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserEventsHistory = async () => {
    try {
      if (isConnected) {
        let dbData = await apis.getUserEventsHistory(address?.toString()); //TODO:address
        // console.log(dbData, "dbDatadbDssssssssssssatadbData");
        setUserHistoryData((prevState) => ({
          ...prevState,
          buyHistory: dbData?.data?.buyHistory,
          claimHistory: dbData?.data?.claimHistory,
          swapHistory: dbData?.data?.swapHistory,
          referralHistory: dbData?.data?.referralHistory,
          airdropHistory: dbData?.data?.airdropHistory,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserWithParents = async () => {
    if (isConnected && address) {
      try {
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: [],
          TeamUsersData: [],
        }));
        const response = await apis.getUserWithParents(address?.toString());
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: response?.data?.children?.filter(
            (user) => user.level === response?.data?.user?.level + 1
          ),
          TeamUsersData: response?.data?.children?.filter(
            (user) =>
              user.level >= response?.data?.user?.level + 2 &&
              user.level <= response?.data?.user?.level + 7
          ), //&& user.level <= user.level + 7
        }));
        // console.log(response, "sfdaaaaaaaaaaaaaaaaaaaaaa");
      } catch (error) {
        setUserDatabaseData((prevState) => ({
          ...prevState,
          DirectUsersData: [],
          TeamUsersData: [],
        }));
        console.error("Error connecting user:", error);
        // throw error;
      }
    }
  };

  const getAllUserReferrals = async () => {
    if (isConnected && address) {
      try {
        const response = await apis.getAllUserReferrals(address?.toString());
        // console.log(response,"responseresponseresponse222222222221");
        setUserDatabaseData((prevState) => ({
          ...prevState,
          totalChildrensTeam: response?.data?.totalChildren,
          totalDirectUsers: response?.data?.level1Referrals,
          wallet_address: address?.toString(),
          direactBusiness: response?.data?.business,
          totalReferralReward: response?.data?.referralReward,
          totalReferralShareOfDirectUsers: response?.data?.totalReferralShareOfDirectUsers,
          totalAirdrop: response?.data?.totalAirdrop,
        }));
        // console.log(response, "responseresponseresponse222");
      } catch (error) {
        console.error("Error connecting user:", error);
        // throw error;
      }
    }
  };

  // console.log(address, "addressaddress");
  // console.log(isConnected, "isConnectedisConnected");

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// ICO ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const GetICOContractValues = async () => {
    try {
      if (address && isConnected) {
        const providers = process.env.REACT_APP_RPC_URL_BNB;
        const provider = new ethers.providers.JsonRpcProvider(providers);

        const dvtICOsaleContracts = new ethers.Contract(
          DoofyventureICOSaleAddress.address,
          DoofyventureICOSaleAbi.abi,
          provider
        );

        // Create contract instances
        const USDTContracts = new ethers.Contract(
          usdtContractAddress.address,
          tokenContractAbis.abi,
          provider
        );

        const TokensInContract = await dvtICOsaleContracts.users(
          address?.toString()
        );

        const usdtInWallet = await USDTContracts.balanceOf(
          address?.toString()
        );

        const TimeInDays = await dvtICOsaleContracts.releaseInterval();
        const isICOSale = await dvtICOsaleContracts.isICOSale();
        const icoSalePrice = await dvtICOsaleContracts.salePrice();

        setICOContractData((prevState) => ({
          ...prevState,
          dvtBuyICO_tokens: formatUnits(TokensInContract?.lockedBalance,18)?.toString(),
          totaldvtBuyICO_tokens: formatUnits(TokensInContract?.totalBuyAmount,18)?.toString(),
          usdtInWallet: formatUnits(usdtInWallet,18)?.toString(),
          isICOSale: isICOSale,
          icoSalePrice: formatUnits(icoSalePrice, 18)?.toString(),
          remainTimeInICOClaim: ((+TokensInContract?.lastReleaseTime?.toString() + +TimeInDays) * 1000),
        }));
      }
    } catch (error) {
      setloader(false);
    }
  };

  const BuyICOWithUSDT = async (arrayAddresses, usdtAmount) => {
    if (!isConnected) {
      setloader(false);
      return toast.error("Please Connect Your Wallet.");
    }

    try {
      setloader(true);
      // Initialize the provider and signer
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      // Create contract instances
      const USDTContracts = new ethers.Contract(
        usdtContractAddress.address,
        tokenContractAbis.abi,
        signer
      );

      const dvtICOsaleContracts = new ethers.Contract(
        DoofyventureICOSaleAddress.address,
        DoofyventureICOSaleAbi.abi,
        signer
      );

      
      let dvtAmount = +usdtAmount / +icoContractData?.icoSalePrice;
      
      // console.log(dvtAmount,usdtAmount,"dvtAmountusdtAmount");
      
      let dvtTokenToBuy = ethers.utils.parseEther(dvtAmount?.toString());

      let usdtAmountToApprove = ethers.utils.parseEther(usdtAmount?.toString());


      // Check USDT balance for the first buyer
      const usdtBalance = await USDTContracts.balanceOf(arrayAddresses[0]);
      if (usdtBalance.lt(usdtAmountToApprove)) {
        setloader(false);
        return toast.error("Insufficient USDT balance.");
      }

      // Check allowance
      const allowance = await USDTContracts.allowance(
        arrayAddresses[0],
        DoofyventureICOSaleAddress.address
      );

      if (allowance.lt(usdtAmountToApprove)) {
        // Approve the required USDT amount if allowance is insufficient
        let tokenApprove = await USDTContracts.approve(
          DoofyventureICOSaleAddress.address,
          usdtAmountToApprove.toString()
        );
        await tokenApprove.wait();
      }

      // Call the buyTokens function with the array of addresses and the plan index
      const buying = await dvtICOsaleContracts.buyTokens(
        arrayAddresses,
        dvtTokenToBuy
      );
      await buying.wait();
      // let res = await apis.sendEmailAPI({
      //   email:userDatabaseData?.email,
      //   name: userDatabaseData?.userName,
      //   tokens: ethers.utils.formatEther(dvtTokenToBuy?.toString())?.toString()
      // });
      // console.log(res, "resresEmail");
      const bnbLink = `https://bscscan.com/tx/${buying.hash}`;
      // console.log(`Transaction Hash: ${bnbLink}`);
      await GetICOContractValues();
      await getOneUser();
      setloader(false);
    } catch (error) {
      toast.error(`${error.reason ? error.reason : error.message}`);
      console.log(error);
      setloader(false);
    }
  };

  const claimICOBuyedTokens = async () => {
    try {
      setloader(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const dvtICOsaleContracts = new ethers.Contract(
        DoofyventureICOSaleAddress.address,
        DoofyventureICOSaleAbi.abi,
        signer
      );

      const start = await dvtICOsaleContracts.releaseTokens(address);
      await start.wait();
      await GetICOContractValues();
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };


  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// Airdrop /////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  const GetAirdropContractValues = async () => {
    try {
      if (address && isConnected) {
        const providers = process.env.REACT_APP_RPC_URL_BNB;
        const provider = new ethers.providers.JsonRpcProvider(providers);

        const dvtAirdropContracts = new ethers.Contract(
          DoofyVenturesAirdropAddress.address,
          DoofyVenturesAirdropAbi.abi,
          provider
        );

        const TokensInContract = await dvtAirdropContracts.users(address?.toString());
        // console.log(TokensInContract,"TokensInContract");
        const isReadyToClaim = await dvtAirdropContracts.isReadyToClaim();
        const TimeInDays = await dvtAirdropContracts.releaseInterval();
  
        setAirdropContractData((prevState) => ({
          ...prevState,
          remainBalance: formatUnits(TokensInContract?.remainBalance || 0,18)?.toString(),
          totalRecivedAmount: formatUnits(TokensInContract?.totalRecivedAmount || 0,18)?.toString(),
          isReadyToClaim: isReadyToClaim,
          remainTimeInAirdropClaim: ((+TokensInContract?.lastReleaseTime?.toString() + +TimeInDays) * 1000),
        }));
      }
    } catch (error) {
      setloader(false);
    }
  };


  const claimAirdropRecivedTokens = async () => {
    try {
      setloader(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const dvtAirdropContracts = new ethers.Contract(
        DoofyVenturesAirdropAddress.address,
        DoofyVenturesAirdropAbi.abi,
        signer
      );

      const start = await dvtAirdropContracts.releaseTokens(address);
      await start.wait();
      await GetAirdropContractValues();
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  const HandleAirDropTokens = async (_address, _amount) => {
    try {
      setloader(true);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const dvtAirdropContracts = new ethers.Contract(
        DoofyVenturesAirdropAddress.address,
        DoofyVenturesAirdropAbi.abi,
        signer
      );

      const start = await dvtAirdropContracts.airDropTokens(_address,_amount);
      await start.wait();
      await GetAirdropContractValues();
      setloader(false);
      toast.success("Airdrop Transfered")
    } catch (error) {
      setloader(false);
      console.log(error);
      toast.error(`${JSON.stringify(error.reason)}`);
    }
  };

  useEffect(() => {
    if (isConnected) {
      GetValues();
      GetICOContractValues();
    }
  }, [address]);

  return (
    <>
      <Store.Provider
        value={{
          getAllUserReferrals,
          getUserWithParents,
          claimTokens,
          getOneUser,
          loader,
          GetValues,
          setloader,
          getUserEventsHistory,
          getProviderPresaleContract,
          copyWalletAddress,
          copyToClipboardReferral,
          BuyWithUSDTandUSDC,
          userHistoryData,
          userDatabaseData,
          userContractData,
          setUserDatabaseData,
          showConfirmationModal,
          setShowConfirmationModal,
          referraAddress,
          setReferraAddress,
          /////swap////////
          swapDoofyTokens,

          /////ICO/////////
          BuyICOWithUSDT,
          claimICOBuyedTokens,
          icoContractData,
          
          /////Airdrop/////////
          claimAirdropRecivedTokens,
          GetAirdropContractValues,
          HandleAirDropTokens,
          airdropContractData
        }}
      >
        {children}
      </Store.Provider>
    </>
  );
};
